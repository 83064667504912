import React, { Component } from 'react';
import { isMobileOnly } from "react-device-detect";
import ReactTooltip from "react-tooltip";

import { mappedMonths } from "../../helpers/danishMonths";
import FavouriteIcon from '../FavouriteIcon';
import CompareIcon from "../CompareIcon";
import { SvgIco } from '../Icons';
import { CrawledBadge, RentedOutBadge } from "../Badges";

import './index.sass';


class LocationTemplate extends Component {

  state = {
    favouriteNotificationWrapperId: `${Math.random()}`.split('.')[1],
    comparableNotificationWrapperId: `${Math.random()}`.split('.')[1],
  }

  handleClick = (e) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();

      const target = e.target;
      const favouriteNotificationWrapper = document.getElementById(this.state.favouriteNotificationWrapperId);
      const comparableNotificationWrapper = document.getElementById(this.state.comparableNotificationWrapperId);
      const isFavouriteNotificationWrapper = favouriteNotificationWrapper && (favouriteNotificationWrapper.contains(target) || target === favouriteNotificationWrapper);
      const isComparableNotificationWrapper = comparableNotificationWrapper && (comparableNotificationWrapper.contains(target) || target === comparableNotificationWrapper);
      const isFavouriteIconWrapper = this.favouriteIconWrapper.contains(target);
      const isComparableIconWrapper = this.comparableIconWrapper.contains(target);
      const isTooltip = this.PersonsTooltipRef && (target === this.PersonsTooltipRef || this.PersonsTooltipRef.contains(target));

      !isComparableIconWrapper
        && !isFavouriteIconWrapper
        && !isFavouriteNotificationWrapper
        && !isComparableNotificationWrapper
        && !isTooltip
        && window.open(this.props.data.url, "_blank");
    }
  };

  handlePersonsTooltip = () => {
    const { showPersonsTooltip } = this.state;

    this.setState({ showPersonsTooltip: !showPersonsTooltip }, () =>
      showPersonsTooltip ? ReactTooltip.hide(this.PersonsTooltipRef) : ReactTooltip.show(this.PersonsTooltipRef)
    );
  };

  afterHide = () =>
    this.setState({ showPersonsTooltip: false });


  render() {

    const {
      comparableLocations,
      component,
      data,
      showDate,
      favoriteLocations,
      hidePrice,
      section,
      siteKey,
      updateComparableLocations,
      updateFavouriteLocations,
    } = this.props;

    const {
      address_line_1,
      closed_month,
      context_info,
      id,
      is_internal,
      photo_alt,
      photo_url,
      postal_code_and_name,
      price_and_area_facts,
      raw_kind,
      secondary_kinds,
      shown_as_rented_out,
      temporarily_unavailable,
      url,
    } = data;

    const { area, persons, price } = price_and_area_facts;
    const infoWrapperHeight = { height: showDate ? 'calc(100% - 196px)' : 'calc(100% - 172px)' };
    const closedMonth = mappedMonths(closed_month);


    return (
      <a className="location-template" href={url} target="_blank" onClick={this.handleClick}>

        { showDate && <p className="location-template__date">{ context_info }</p> }

        <div className="location-template__image-wrapper lazyload"
             data-bg={photo_url}
             data-sizes="auto"
             role="img"
             aria-label={photo_alt}
             style={shown_as_rented_out || temporarily_unavailable ? { filter: 'grayscale(100%)' } : null}
        >
          { is_internal && <CrawledBadge root="grid" /> }
        </div>

        { (shown_as_rented_out || temporarily_unavailable) &&
          <div className={`location-template__badge-wrapper${showDate ? ' with-indentation' : ''}`}>
            <RentedOutBadge
              label={temporarily_unavailable ? I18n.t('activerecord.attributes.location.states.temporarily_unavailable') : null}
              root="list"
              month={siteKey === 'dk' ? closedMonth : null}
              sale={section !== 'lease'}
            />
          </div>
        }


        <div ref={el => this.comparableIconWrapper = el}>
          { section === 'lease' && !isMobileOnly &&
            <CompareIcon
              component={component}
              comparableLocations={comparableLocations}
              indentation={showDate}
              kind={raw_kind}
              locationId={id}
              notificationWrapperId={this.state.comparableNotificationWrapperId}
              siteKey={siteKey}
              tooltipPosition={'left'}
              updateComparableLocations={updateComparableLocations} />
          }
        </div>

        <div ref={el => this.favouriteIconWrapper = el}>
          <FavouriteIcon
            favoriteLocations={favoriteLocations}
            indentation={showDate}
            locationId={id}
            notificationWrapperId={this.state.favouriteNotificationWrapperId}
            section={section}
            siteKey={siteKey}
            tooltipPosition={'left'}
            updateFavouriteLocations={updateFavouriteLocations}
          />
        </div>

        <div className="location-template__info-block-wrapper" style={infoWrapperHeight}>

          <div className="location-template__location-kinds">
            <span className="location-template__location-kind">
              { I18n.t(`generic.${raw_kind}`) }
            </span>
            { secondary_kinds.length ? <span>+{ secondary_kinds.length }</span> : null }
          </div>

          <div className="location-template__location-address">
            <p>{ address_line_1 }</p>
            <p>{ postal_code_and_name }</p>
          </div>

          { raw_kind !== 'parking' && raw_kind !== 'virtual_office' && area.value &&
            <div className="location-template__info-wrapper location-template__info-row">
              <p className="location-template__info-label">
                <SvgIco name={persons ? persons.icon : area.icon} size={22} />
                { persons
                  ? persons.label
                  : area.icon === 'desks'
                  ? I18n.t('generic.desks')
                  : area.label
                }
              </p>
              <p className="location-template__info-value">
                { persons ? persons.value : area.value } { area.icon !== 'desks' && !persons && area.unit }
                { persons && persons.tooltip &&
                  <span className="location-template__info-tooltip"
                        data-for={'persons-tooltip-' + id}
                        data-tip={persons.tooltip}
                        onClick={this.handlePersonsTooltip}
                        ref={ref => this.PersonsTooltipRef = ref}>
                    <SvgIco name="info_outline" size={14} />
                    <ReactTooltip
                      afterHide={this.afterHide}
                      effect="solid"
                      id={'persons-tooltip-' + id}
                      place="left"
                      className="location-template-tooltip"
                    />
                  </span>
                }
              </p>


            </div>
          }

          { !hidePrice &&
            <div className="location-template__info-wrapper">
              <div className="location-template__info-row">
                <p className="location-template__info-label">
                  { price.label }
                </p>
                <p className="location-template__info-value">
                  { price.value } { price.unit }
                </p>
              </div>
            </div>
          }
        </div>
      </a>
    )
  }
}

export default LocationTemplate;
